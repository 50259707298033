body {
    font-family: 'Courier', monospace;
  }


.app-container {
  position: relative;
}
.gradient-background {
    background: linear-gradient(90deg, #fea57e 0%, #fea57e 20%, #fad496 55%, #fe854f 100%);
    min-height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
.banner-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 150px;
  }
  
  .orange-shape {
    content: "";
    position: absolute;
    top: auto;
    left: auto;
    width: 150%;
    height: 150px;
    border-radius: 0 0 0% 0%;
    z-index: -1;
  }
  
  .page-header {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin: 2rem 0;
    background: linear-gradient(90deg, #FF8C00, #FFA500);
    background-clip: text;
    -webkit-background-clip: text;
    color: white;
    letter-spacing: -0.02em;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Button styles */
  .semi-transparent-button {
    background-color: rgba(255, 255, 255, 0.3);
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    box-shadow: none;
    transition: all 0.3s ease;
    font-family: Arial, sans-serif; /* Override Courier font */
    font-size: 14px;
    cursor: pointer;
  }
  
  .semi-transparent-button:hover {
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  .semi-transparent-button:active {
    transform: translateY(1px);
  }
  
  .semi-transparent-button span {
    font-family: Arial, sans-serif;
  }
  
  .trash-button {
    background-color: whitesmoke;       /* No default background */
    color: #FF4B2B;                      /* Sets icon stroke color to orange */
    border: none;
    border-radius: 0.375rem;            /* Slight rounding, optional */
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease, opacity 0.2s ease;
  }
  
  /* Hover effect: subtle background tint or “dimming” */
  .trash-button:hover:not(:disabled) {
    background-color: rgba(255, 75, 43, 0.1);
    /* or, if you prefer dimming the icon instead:
       opacity: 0.7;
    */
  }
  
  /* Active state: a bit more pronounced tint */
  .trash-button:active:not(:disabled) {
    background-color: rgba(255, 75, 43, 0.2);
  }
  