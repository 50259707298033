/* General Container Styles */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.gradient-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #fea57e 0%, #fea57e 20%, #fad496 55%, #fe854f 100%);
  z-index: -1;
}

/* Page Header */
.page-header {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin: 2rem 0;
  background: linear-gradient(90deg, #FF8C00, #FFA500);
  background-clip: text;
  -webkit-background-clip: text;

  -webkit-text-fill-color: #ffff;
  letter-spacing: -0.02em;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Loading Screen */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(90deg, #fea57e 0%, #fea57e 20%, #fad496 55%, #fe854f 100%);
}

/* Banner Container */
.banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.white-overlay {
  max-width: 90%;
  height: auto;
  object-fit: contain;
}

/* Scroll Area */
.scroll-area {
  height: 50rem;
  width: 100%;
  max-width: 50rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1.5rem;
  padding: 2rem;
  backdrop-filter: blur(8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Event Container */
.event-container {
  background: linear-gradient(145deg, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 140, 0, 0.1);
  border-radius: 1.5rem;
  padding: 2.5rem 2rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 90%;
  min-width: 600px;
  margin: 2rem auto;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.event-container:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.15);
}

/* Typography */
.event-title {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #FF8C00, #FFA500);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.event-prize {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #FF8C00;
  margin: 0.5rem 0;
}

.event-date {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

/* Button Styles */
button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
}

.action-button {
  background: linear-gradient(90deg, #FF8C00, #FFA500) !important;
  color: white !important;
  padding: 0.75rem 1.5rem !important;
  border-radius: 0.5rem !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  cursor: pointer !important;
  width: 200px !important;
  border: none !important;
  box-shadow: 0 4px 12px rgba(255, 140, 0, 0.2) !important;
}

.action-button:hover {
  transform: translateY(-1px) !important;
  box-shadow: 0 6px 16px rgba(255, 140, 0, 0.25) !important;
}

.action-button:disabled {
  background: #cccccc !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
  transform: none !important;
}

/* Dialog Styles */
.dialog-content {
  background: linear-gradient(145deg, rgba(255,255,255,0.95), rgba(255,255,255,0.85));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 140, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90vw;
}

.dialog-title {
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  background: linear-gradient(90deg, #FF8C00, #FFA500);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

/* Toast Styles */
.custom-toast {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  padding: 1rem 1.5rem !important;
  border-radius: 0.75rem !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  min-width: 200px !important;
  text-align: center !important;
}

.custom-toast.success-toast {
  background: rgba(255, 255, 255, 0.95) !important;
  color: #4CAF50 !important;
  border: 1px solid rgba(76, 175, 80, 0.2) !important;
}

.custom-toast.error-toast {
  background: rgba(255, 255, 255, 0.95) !important;
  color: #ff4444 !important;
  border: 1px solid rgba(255, 68, 68, 0.2) !important;
}

/* Work Grid */
.work-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin: 2rem 0;
}

/* Work Box */
.work-box {
  background: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 140, 0, 0.1);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.work-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(255, 140, 0, 0.1);
}

.work-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  text-align: center;
}

.submit-work-button {
  background: linear-gradient(90deg, #FF8C00, #FFA500) !important;
  color: white !important;
  border: none !important;
  padding: 0.75rem 1.5rem !important;
  border-radius: 0.5rem !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  cursor: pointer !important;
  width: auto !important;
}

.submit-work-button:hover {
  transform: translateY(-1px) !important;
  box-shadow: 0 6px 16px rgba(255, 140, 0, 0.25) !important;
}

.close-button {
  padding: 0.75rem 2rem;
  border-radius: 0.5rem;
  background: #f1f1f1;
  color: #666;
  border: none;
  transition: all 0.2s ease;
  cursor: pointer;
}

.close-button:hover {
  background: #e5e5e5;
}

/* Message Styles */
.no-works-message {
  text-align: center;
  color: #666;
  font-style: italic;
  padding: 2rem;
  grid-column: 1 / -1;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .page-header {
    font-size: 2.5rem;
    margin: 1.5rem 0;
  }
  
  .scroll-area {
    width: 95%;
    height: 40rem;
  }

  .event-container {
    padding: 1.5rem 1rem;
  }

  .event-title {
    font-size: 1.5rem;
  }

  .work-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .page-header {
    font-size: 2rem;
    margin: 1rem 0;
  }

  .event-container {
    width: 95%;
  }

  .action-button {
    width: 100% !important;
  }
}

.submit-dialog {
  z-index: 1000;
}

/* Style for the nested confirmation dialog */
.confirmation-dialog {
  z-index: 1001; /* Higher than parent dialog */
}